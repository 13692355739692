<template>
    <div>
        Registration completed
    </div>
</template>

<script>

// Visit this page using Vue.js hash mode URL. Ex. http://localhost:8083/#/registered
export default {
    name: 'Registered',
    data() {
        return {}
    },
    mounted() {
        window.location.assign('/');
    }
};

</script>